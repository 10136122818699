<template>
  <div id="divUsersManualDetailWrapper">
    <VueWrapperNarrow class="wrapper-narrow">
      <div v-html="contentHTML"></div>
    </VueWrapperNarrow>
  </div>
</template>
<script>
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'UserManualDetail',
  components: { VueWrapperNarrow },
  data() {
    return {
      contentHTML: null,
    };
  },
  created() {
    this.contentHTML = new StorageHelper({
      id: process.env.VUE_APP_STORAGE_USER_MANUAL_CONTENT_KEY,
    }).get();
  },
};
</script>

<style lang="scss" scoped>
.wrapper-narrow {
  padding-top: palette-space-level('60');
}
</style>
